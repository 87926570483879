<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10247')}}
							</h2>
							<div class="inner__text">
								{{t('10248')}}
							</div>
						</div>
						<div class="inner__wrap">
							<!-- [D] Select 박스 -->
							<div class="slt">
								<input 
									type="text" 
									class="ipt-frm ipt-slt" 
									:placeholder="t('10716')" 
									readonly 
									v-model="selectReason"
									ref="selectReasonInput"
									@focus="onFocusReason(true)">
								<div class="frms__btns">
									<button type="button" class="btn-frmreset" @click="onFocusReason(!isSelectReason)">
										<i class="icon icon-down"></i>
									</button>
								</div>
								<!-- [D] 선택시 input에 입력되고, icon은 제거 -->
								<ul class="slt__list" :class="{'slt__list-show': isSelectReason}">
									<li 
										v-for="item in reasonList" 
										:key="item.code" 
										@click="onClickReason(item)"
										>{{item.code_nm}}</li>
								</ul>
							</div>
							<!-- [D] 기타(직접 작성) 선택시 표시 -->
							<textarea 
								v-if="selectReasonCode === '99'"
								class="ta" 
								:placeholder="t('10829')" 
								style="margin-top:8px" v-model="reason"></textarea><!-- 재번역 체크 -->
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn btn-primary purple" @click="goMypick">{{t('10250')}}</button>
			<button 
				type="button" 
				class="btn" 
				:class="selectReason.length > 0 && (selectReasonCode === '99' ? reason.length >= 5 : true) ? 'btn-confirm' : 'btn-disabled'"
				@click="onClickWithdraw"
				>{{t('10249')}}</button>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore} from "vuex"
import api from "@/urls/mmb09";
import { ref, computed, onMounted } from "vue"
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {	
	components: {
		ErrorAlert,
	},
	setup: function(){
		const router = useRouter();
		const store = useStore();
		const openErrorAlert = ref(false)
		const selectReasonInput = ref(null);
		const selectReason = ref("");
		const selectReasonCode = ref("");
		const isSelectReason = ref(false);
		const reason = ref("");

    const mcd00 = computed(() => store.state.mcd00.mcd00);

    const userData = computed(() => store.state.userData);

		const reasonList = computed(()=> (mcd00.value["R75"] ?? []));

		const goMypick = () => {
			router.push("/mypage");
		}

		const goBack = () =>{
			router.go(-1);
		}

		const onClickReason = (item)=> {
			selectReasonCode.value = item.code;
			selectReason.value = item.code_nm;
			isSelectReason.value = false;
		}

		const onFocusReason = (isFocus) =>{
			if(isFocus){
				selectReasonInput.value.focus();
			}
			isSelectReason.value = isFocus;
		}

		const onClickWithdraw = () =>{
			if(selectReason.value === "") { return; }

			api.getMmb0901({
				mem_id: userData.value.mem_id,
				Withdrawal_cd: selectReasonCode.value,
				Withdrawal_desc: reason.value
			}).then(data => {
				//console.log(data);
				if(data.data.Res_cd === "0000"){
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "회원탈퇴가 완료되었습니다."});
					openErrorAlert.value = true;
					//alert("회원탈퇴가 완료되었습니다.");
      		store.commit("SET_USERDATA", {userData: {}});
					router.push("/member/withdraw/complete");
				}
				else {
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "회원탈퇴에 실패하였습니다."});
					openErrorAlert.value = true;
					//alert(`회원탈퇴에 실패하였습니다. (${data.data.Res_msg})`);
				}
			});
		}

    onMounted(() => {
      if(!("R75" in mcd00.value)){ 
        store.dispatch("mcd00/fetchMcd00", {
          code_grp: "R75"
        });
      }
    });
	const { t }= useI18n() //번역필수 모듈

		return {
			goMypick,
			goBack,
			selectReason,
			selectReasonCode,
			selectReasonInput,
			reasonList,
			onClickReason,
			onFocusReason,
			isSelectReason,
			reason,
			onClickWithdraw,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			t,
			i18n,
		}
	}
}
</script>